import { useState, useEffect, useRef } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library';

const Default = () => {

	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState([]);
	const [scanning, setScanning] = useState(true);
	const [scannedItem, setScannedItem] = useState({});
	const scannerRef = useRef(null);

	// ZXingリーダーの起動
	useEffect(() => {

		if (!scanning) return;
		if (!scannerRef.current) return;

		// ヒントマップを作成
		const hints = new Map();

		// JANコード/EAN-13とEAN-8のみを検出¥
		hints.set(DecodeHintType.POSSIBLE_FORMATS, [
			BarcodeFormat.EAN_13,
			BarcodeFormat.EAN_8
		]);
		
		// リーダーを作成
		const reader = new BrowserMultiFormatReader(hints);

		reader.decodeFromConstraints(
			{
				audio: false,
				video: {
					facingMode: 'environment',
					advanced: [{ zoom: 2.0 }]
				},
			},
			scannerRef.current,
			(result, error) => {
				if (result){
					
					// バーコードが検出された場合
					const code = result.getText();
			
					// JANコード（EAN-13またはEAN-8）のみを受け入れる
					if (/^\d+$/.test(code) && (code.length === 13 || code.length === 8)) {
						console.log('バーコード検出:', code);
						handleDetected(code);
					}
					else {
						console.log('無効なコード検出:', code);
					}
				};
				//if (error) console.log(error);
			}
		);
		
		return () => {
			reader.reset();
		}
	}, []);

	// loadingがtrueの際、時間差でscanningをtureにする
	useEffect(() => {

		if(loading){
			setTimeout(() => {
				setScanning(true);
			}, 2000);
		}

	}, [loading]);

	// scanningがtureの際、loadingをfalseにする
	useEffect(() => {

		if(scanning){
			setLoading(false);
		}

	}, [scanning]);

	//商品を識別してモーダル表示を行う
	const handleDetected = async (item_id) => {

		try{
			if (!item_id) {
				throw new Error('item_idがありません');
			}

			if (!scanning) {
				throw new Error('scanningが有効ではありません');
			}

			const refItem = await API.Item.Get(item_id);
			const item = refItem?.item ?? null;

			if (!item || Object.keys(item).length === 0) {
				throw new Error('商品が見つかりません');
			}

			//スキャンを止める
			setScanning(false);

			//モーダルを表示する
			setScannedItem({
				item_id: item_id,
				product_no: item.product_no,
				product_name: item.product_name,
				gousu: item.gousu,
				price: Number(item?.price ?? 0),
				unit_price: Number(item?.unit_price ?? 0),
				piece: Number(item?.minimum_unit ?? 1),
				minimum_unit: item.minimum_unit,
				category1_name: item.category1_name,
				category1_code: item.category1_code,
				category2_name: item.category2_name,
				category2_code: item.category2_code,
				genre_name: item.genre_name,
				genre_code: item.genre_code,
				catalog_no: item.catalog_no,
				keyword: item.keyword,
				sort: item.sort,
				boxed_num: item.boxed_num,
				url: item.url,
				pre_order_flag: item.pre_order_flag,
				pre_order_request: item?.pre_order_flag ? 1 : 0,
				stock_flag: item.stock_flag,
				message: '',
			});
		}
		catch(err){
			console.log(err.message);
		}

	};

	//カートに入れる
	const handleAddCart = async () => {

		try {

			if (Object.keys(scannedItem).length === 0) {
				throw new Error('商品が見つかりませんでした。');
			}

			const ref = await API.Cart.Add(scannedItem);

			if (ref.error) {
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			//元のモーダルを消す
			setScannedItem({});
			//案内モーダルを出す
			setAlert(['商品をカートに入れました。']);
		} catch (err) {
			setAlert([err.message]);
		}

	};

	//モーダルを閉じた時の手続き
	const handleClose = () => {
		setScannedItem({});
		setAlert([]);
		setLoading(true);
	};

	return (
		<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
			<ModalAlert data={alert} onClick={handleClose} />
			<Modal show={Object.keys(scannedItem).length > 0} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>カートに入れますか？</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>JANコード：{scannedItem.item_id}</p>
					<p>品番：{scannedItem.product_no}</p>
					<p>品名：{scannedItem.product_name}</p>
					<p>号数：{scannedItem.gousu}</p>
					<p>定価：{Number(scannedItem?.price ?? 0).toLocaleString()}円</p>
					<p>最低発注枚数：{Number(scannedItem.minimum_unit ?? 0).toLocaleString()}個</p>
					<p>在庫管理：{scannedItem.stock_flag}</p>
					<Button variant="primary" onClick={handleAddCart}>カートに入れる</Button>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						閉じる
					</Button>
				</Modal.Footer>
			</Modal>

			<Row>
				<Col sm={12}>
					<h4>バーコード発注</h4>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<Button variant="primary" onClick={() => { window.location.href = '/cart/'; }}>
						商品カートに移動する
					</Button>
				</Col>
			</Row>

			<Row style={{ marginTop: 20 }}>
				<Col sm={12} style={{ overflow: 'hidden', textAlign: 'center' }}>
				<video
					ref={scannerRef}
					style={{
						display: scanning ? 'block': 'none',   
						width: '100%',  // 親要素に合わせる
						maxWidth: '600px', // PCでは最大600pxに制限
						height: 'auto',  // 縦横比を維持
					}}
				/>
				{loading &&
					<>
						<Image src="/img/loading.gif" />
						カメラ読み込み中
					</>
				}
				</Col>
			</Row>

		</Container>
	);

};

export default withRouter(Default);
